<template>
  <Main center>
    <Status heading="Congratulations" name :amount="amount">
      <template v-slot:picture>
        <svg width="85" height="105" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M84.505 2.523a4.186 4.186 0 00-3.834-2.56H4.148c-1.1 0-2.155.436-2.932 1.213A4.138 4.138 0 000 4.103v96.624a4.14 4.14 0 001.216 2.927 4.157 4.157 0 002.932 1.213h76.526a4.16 4.16 0 002.932-1.214 4.145 4.145 0 001.216-2.926V4.103a4.06 4.06 0 00-.314-1.58h-.003zm-.607 98.204a3.221 3.221 0 01-.946 2.276 3.233 3.233 0 01-2.28.944H4.147a3.23 3.23 0 01-2.281-.943 3.219 3.219 0 01-.946-2.277V4.103a3.222 3.222 0 01.947-2.276 3.235 3.235 0 012.28-.945h76.526a3.247 3.247 0 012.995 2.026 3.185 3.185 0 01.232 1.195l-.003 96.624z"
            fill="#3F3D56"
          />
          <path
            d="M68.535 22.775h-23.05a1.846 1.846 0 01-1.843-1.84 1.839 1.839 0 011.844-1.841h23.05a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.84zM68.535 32.437h-23.05a1.847 1.847 0 01-1.706-1.135 1.838 1.838 0 011.706-2.546h23.05a1.849 1.849 0 011.706 1.136 1.838 1.838 0 01-1 2.406c-.224.092-.464.14-.706.14z"
            fill="#3F3D56"
          />
          <path
            d="M33.66 37.94H16.815a2.08 2.08 0 01-2.075-2.071v-19.84a2.071 2.071 0 012.075-2.071H33.66a2.079 2.079 0 012.074 2.07v19.84a2.079 2.079 0 01-2.075 2.07z"
            fill="#22C58B"
          />
          <path
            d="M68.376 52.223H16.283a1.846 1.846 0 01-1.844-1.841 1.838 1.838 0 011.845-1.84h52.092a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.84zM68.376 61.885H16.283a1.846 1.846 0 01-1.844-1.84 1.838 1.838 0 011.845-1.84h52.092a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.84zM68.376 71.547H16.282a1.846 1.846 0 01-1.842-1.84 1.839 1.839 0 011.842-1.84h52.093a1.849 1.849 0 011.706 1.135 1.838 1.838 0 01-1.706 2.545zM68.376 81.21H16.283a1.846 1.846 0 01-1.844-1.84 1.838 1.838 0 011.845-1.841h52.092a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.84zM68.376 90.872H16.283a1.846 1.846 0 01-1.844-1.84 1.838 1.838 0 011.845-1.841h52.092a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.841z"
            fill="#CCC"
          />
          <path d="M42 94c11.598 0 21-9.402 21-21s-9.402-21-21-21-21 9.402-21 21 9.402 21 21 21z" fill="#22C58B" />
          <path
            d="M40.045 81c-.467 0-.921-.152-1.295-.436l-.023-.018-4.878-3.782a2.188 2.188 0 01-.83-1.456 2.226 2.226 0 01.428-1.624 2.178 2.178 0 011.437-.842 2.14 2.14 0 011.603.435l3.16 2.456 7.466-9.873a2.177 2.177 0 011.435-.841 2.139 2.139 0 011.603.434l-.046.066.048-.065a2.221 2.221 0 01.4 3.08L41.771 80.14a2.174 2.174 0 01-.763.632 2.144 2.144 0 01-.96.224l-.003.003z"
            fill="#fff"
          />
        </svg>
      </template>

      <template v-slot:details>
        <P>You have been approved for</P>
      </template>

      <template v-if="version === 'v1'" v-slot:additionalDetails>
        <P>Talk to the merchant or sales rep to get started!</P>
      </template>

      <template v-else v-slot:cta>
        <Button fixed expanded large :to="$route.meta.next">Start Shopping</Button>
      </template>
    </Status>
  </Main>
</template>

<script>
import { useStore } from 'vuex'

import { moneyMask } from '../helpers/general'

import Main from '../components/Main.vue'
import Status from '../components/Status.vue'
import Button from '../components/common/Button'
import P from '../components/common/P'

export default {
  name: 'Approved',
  components: {
    Button,
    Main,
    Status,
    P
  },
  setup() {
    return {
      store: useStore()
    }
  },
  computed: {
    amount() {
      return moneyMask(this.store.getters['application/getAmount'])
    },
    version() {
      return this.store.getters['application/getVersion']
    }
  }
}
</script>
